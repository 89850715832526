body {
  margin: 0;
  font-family: "Rozha One", serif;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.noTextOrnamentation {
  text-decoration: none;
  color: #101010;
  font-style: italic;
}

.MainPageBufferSpace {
  padding-top: 60px;
}

@media only screen and (max-width: 600px) {
  .Spinner__enclosure {
    position: sticky;
    z-index: 1;
    background-color: white;
    display: flex;
    top: 300px;
    padding-left: 30vw;
  }
}
